<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction, getParams, downloadAction } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import moment from 'moment'
import { orderStateList2, bedType as bedTypeList } from '@/utils/textFile'
import { hotelMixin } from '@/mixins/orderProcessing/hotelMixin'

export default {
  name: 'hotel',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mixins: [hotelMixin],
  mounted() {
    this.getPage()
  },
  methods: {
    getPage() {
      api.command.getList.call(this, {
        url: '/room/order/page',
        current: 1,
      })
    },
    //倒计时 暂时功能去除与按钮权限冲突 无法显示
    countDownFun(time, i) {
      let startTime = new Date() //当前时间
      let end = new Date(time) //结束时间
      if (!end || !startTime || !time.includes('-')) {
        return ''
      }
      // console.log('清除定时器startTime: ',startTime)
      // console.log('清除定时器end: ',end)
      // console.log('清除定时器end - startTime: ',end - startTime)
      let result = parseInt((end - startTime) / 1000) //计算出豪秒
      let d = parseInt(result / (24 * 60 * 60)) //用总共的秒数除以1天的秒数
      let h = parseInt((result / (60 * 60)) % 24) //精确小时，用去余
      let m = parseInt((result / 60) % 60) //剩余分钟就是用1小时等于60分钟进行趣余
      let s = parseInt(result % 60)
      //当倒计时结束时，改变内容
      if (!result) {
        return ''
      }
      // console.log('清除定时器',result)
      if (result <= 0) {
        console.log('清除定时器result', startTime, '////' + end, '---' + time, 'result:' + result)
        clearInterval(this.records[i].countDownFn) //清除定时器
        this.getPage()
        // 重新请求列表
        return '清除定时器'
      }
      if (h < 10) {
        h = '0' + h
      }
      if (s < 10) {
        s = '0' + s
      }
      if (h == 0 && m == 0) {
        return '' + s
      } else if (h == 0) {
        return m + ':' + s
      } else if (d == 0) {
        return h + ':' + m + ':' + s
      } else {
        return d + ':' + h + ':' + m + ':' + s
      }
    },
    // 页面多个倒计时 归零时清除
    countDown(i) {
      let that = this
      let item = that.records[i]
      that.records[i].countDownFn = setInterval(() => {
        if (that.countDownFun(item.countDownEndTime, i) == '清除定时器') {
          // console.log('清除定时器1',that.countDownFun(item.countDownEndTime, i))
          // clearInterval(that.records[i].countDownFn) //清除定时器
          // this.getPage()
        } else {
          item.countDownTime = that.countDownFun(item.countDownEndTime, i)
          that.$set(that.list, item.countDownTime, item.countDownTime)
          that.$forceUpdate()
        }
      }, 1000)
    },
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号',
        },
        {
          name: '出售商品',
          type: 'input',
          key: 'productName',
        },
        {
          name: '入住时间',
          type: 'rangePicker',
          keys: ['checkInTimeStart', 'leaveTimeEnd'],
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName',
        },
        {
          name: '入住人',
          type: 'input',
          key: 'buyName',
        },
        {
          name: '电话',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入购买人电话',
        },
        // {
        //   name: '床类型',
        //   type: 'select',
        //   key: 'bedType',
        //   typeData: bedTypeList(),
        // },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          isId: true,
          align: 'left',
          sorter: (a, b) => a.orderSn - b.orderSn,
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.orderItemList[0].productPic} />
                <div>
                  {records.hotelName} {records.orderItemList[0].productName} {records.roomName}
                </div>
              </div>
            )
          },
        },
        // {
        //   dataIndex: 'bedType',
        //   title: '床类型',
        //   align: 'left',
        //   //   filters: bedTypeList,
        //   //   filterMultiple: false,
        //   customRender: (text, records) => {
        //     return <div class="product-order-item">{records.orderItemList[0]?.bedTypeName}</div>
        //     /* return <div class="product-order-item">{bedTypeList().find((f) => f.value == text)?.text || ''}</div> */
        //   },
        //   onExport: (records) => records.orderItemList[0]?.bedTypeName,
        // },
        {
          dataIndex: 'shopName',
          title: '店铺名称',
          align: 'left',
        },
        {
          dataIndex: 'concatName',
          title: '入住人',
          align: 'left',
        },
        {
          dataIndex: 'concatPhone',
          title: '电话',
          align: 'left',
          customRender: function customRender(text, records) {
            return <div style={{ fontSize: '12px' }}>{text}</div>
          },
        },
        {
          dataIndex: 'productQuantity',
          title: '间数',
          align: 'center',
          width: 100,
          // customRender: (text, records) => {
          //   return records.orderItemList[0].productQuantity
          // },
        },
        {
          dataIndex: 'salesJsAmount',
          title: '结算金额',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                <span>{(records.salesJsAmount || 0).toFixed(2)}</span>
              </div>
            )
          },
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
          width: 100,
          customRender: (text, records) => {
            if (records.refundAmount) {
              return (
                <div>
                  <span>{records.payAmount.toFixed(2)}</span>
                  <span>（{records.refundAmount.toFixed(2)} 已退）</span>
                </div>
              )
            } else {
              return <div>{records.payAmount.toFixed(2)}</div>
            }
          },
        },
        {
          dataIndex: 'checkInTime',
          title: '入住时间',
          align: 'left',
          customRender: function customRender(text, records) {
            let txt = records.checkInTime
              ? moment(records.checkInTime).format('YYYY/MM/DD')
              : ''
            return (
              <div style={{ fontSize: '12px' }}>
                <span>{txt}</span>
              </div>
            )
          },
        },
        {
          dataIndex: 'leaveTime',
          title: '离店时间',
          align: 'left',
          customRender: function customRender(text, records) {
            let aState = records.leaveTime && records.leaveTime ? true : false
            let a = moment(records.checkInTime).unix()
            let b = moment(records.leaveTime).unix()
            let day = (b - a) / (24 * 60 * 60)

            let txt = records.leaveTime
              ? moment(records.leaveTime).format('YYYY/MM/DD')
              : ''

            return (
              <div style={{ fontSize: '12px' }}>
                {aState ? (
                  <span>
                    {txt}（{day}天）
                  </span>
                ) : (
                  <span>{txt}</span>
                )}
              </div>
            )
          },
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          type: 'badge',
          filters: orderStateList2,
          filterMultiple: false,
          align: 'left',
          render(data, records) {
            return {
              // showDot: true,
              name: records.refundId ? '待退款' : (orderStateList2.find((e) => e.value == data) || { text: '' }).text,
              color: records.refundId ? 'red' : 'transparent',
            }
          },
          onExport: (text) => (orderStateList2.find((e) => e.value == text) || { text: '' }).text,
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '开票信息',
                display:  records.invoiceStatus && records.invoiceStatus != 0,
                onClick: () => this.onInvoice(records),
              },
              {
                name: '无房间',
                display: records.status == '102' && records.comboType != 0 ,
                type: 'pop',
                popTitle: '确认无房间，关闭订单',
                icon: () => <a-icon style="color:#f00" theme="filled" type="info-circle" />,
                onClick: () => {
                  api.order.haveRoom.call(this, {
                    url: '/room/order/confirmNoRoom',
                    params: {
                      orderId: records.id,
                    },
                  })
                },
              },
              {
                name: '确认有房',
                display: records.status == '102' && records.comboType != 0,
                type: 'pop',
                popTitle: '确认后，将短信通知用户安排入住',
                icon: () => <a-icon style="color:#f00" theme="filled" type="info-circle" />,
                onClick: () => {
                  api.order.haveRoom.call(this, {
                    url: '/room/order/confirm',
                    params: {
                      orderId: records.id,
                    },
                  })
                },
              },
              {
                name: '去处理',
                display: records.refundId,
                onClick: () => this.$router.push(`/orderProcessing/refund?id=${records.refundId}`),
              },
              {
                name: '核销入住',
                display: records.status == '1' && !records.refundId && records.fromType != '14',
                type: 'pop',
                popTitle: () => (
                  <span>
                    确认用户已经到店，核销掉用户
                    <br />
                    购买的房间
                  </span>
                ),
                icon: () => <a-icon style="color:#f00" theme="filled" type="info-circle" />,
                onClick: () => {
                  api.order.refundOff.call(this, {
                    url: '/room/order/hxCode',
                    params: {
                      orderId: records.id,
                    },
                  })
                },
              },
              {
                type: 'pop',
                popTitle: '确认关闭吗?',
                name: '关闭订单',
                display: records.status == '0',
                onClick: () => this.onBatchDelete([records]),
              },
              {
                name: '查看',
                display: true,
                onClick: () => this.onRowSelectHotel(records),
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    // // 获取选中订单详情
    // getDetailData(id) {
    //   return new Promise((resolve, reject) => {
    //     getAction(`/api/room/order/detail?id=${id}`)
    //       .then((result) => {
    //         resolve(result.data)
    //       })
    //       .catch((e) => reject && reject(e))
    //   })
    // },
    // // 获取套餐详情
    // getPackageDetail(id) {
    //   return new Promise((resolve, reject) => {
    //     getAction(`/api/combo/order/detail?id=${id}`)
    //       .then((result) => {
    //         resolve(result.data)
    //       })
    //       .catch((e) => reject && reject(e))
    //   })
    // },
    // 退款事件
    onRefund({ records, item = [], typeText }) {
      let that = this
      let formData = {}
      item?.forEach((element, index) => {
        formData['offDate' + index] = []
        formData['offNum' + index] = 0
        formData['date' + index] = element.buyDate
        formData['numRoom' + index] = element.unUsedCount
        formData['id' + index] = element.id
      })
      // type手动传参 告诉当前订单类型
      orderConfig.refund({
        data: Object.assign(records, formData, { zNum: item.length }),
        params: { data: { ...records, type: 'hotel', typeText } },
        executeFun: [orderConfig.checkInfo, orderConfig.base, orderConfig.writeOff],
        success({ data, setHidden }) {
          let arr = [...new Array(item.length)].map((child, cIndex) => {
            return {
              orderItemId: data['offDate' + cIndex].length ? data['id' + cIndex] : '',
              num: data['offNum' + cIndex],
            }
          })

          api.order.refund
            .call(that, {
              url: '/room/order/refund',
              params: {
                itemList: arr.filter((a) => a.orderItemId),
                orderId: records.id,
                handleResult: '0',
              },
            })
            .then(setHidden)
        },
      })
    },
    // 核销事件
    onRefundOff({ records, item = [], typeText }) {
      let that = this
      let formData = {}
      item?.forEach((element, index) => {
        formData['offDate' + index] = []
        formData['offNum' + index] = 0
        formData['date' + index] = element.buyDate
        formData['numRoom' + index] = element.unUsedCount
        formData['id' + index] = element.id
      })
      // type手动传参 告诉当前订单类型
      orderConfig.roomWriteOff({
        data: Object.assign(records, formData, { zNum: item.length }),
        params: { data: { ...records, type: 'hotel', typeText } },
        executeFun: [orderConfig.checkInfo, orderConfig.base, orderConfig.writeOff],
        success({ data, setHidden }) {
          let arr = [...new Array(item.length)].map((child, cIndex) => {
            return {
              orderItemId: data['offDate' + cIndex].length ? data['id' + cIndex] : '',
              num: data['offNum' + cIndex],
            }
          })

          api.order.refundOff
            .call(that, {
              url: '/room/order/hxCode',
              params: {
                itemList: arr.filter((a) => a.orderItemId),
                orderId: records.id,
              },
            })
            .then(setHidden)
        },
      })
    },
    // onClose(data) {
    //   orderConfig.close({
    //     data,
    //     params: {
    //       data,
    //     },
    //     executeFun: [
    //       // 表格数据
    //       orderConfig.table,
    //       // 入住信息
    //       orderConfig.checkInfo,
    //       // 基础信息
    //       orderConfig.base,
    //       //
    //       orderConfig.record,
    //     ],
    //     success: ({ setHidden }) => {
    //       api.order.close
    //         .call(this, {
    //           url: '/common/order/close',
    //           params: { id: data.id },
    //         })
    //         .then(setHidden)
    //     },
    //   })
    // },
    // 批量关闭
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete,
            },
          ],
        },
      ]
    },

    // onRowSelect(records) {
    //   const onDetail = (detail) => {
    //     {
    //       orderConfig.completed({
    //         data: detail,
    //         params: {
    //           data: { ...detail, showButton: detail.fromType != '14' },
    //           type: '',
    //         },
    //         executeFun: [
    //           // 表格数据
    //           orderConfig.table,
    //           // 可选择表格
    //           orderConfig.tableTicketsSelect,
    //           // 退款信息
    //           orderConfig.retuenInfo,
    //           // 入住信息
    //           orderConfig.checkInfo,
    //           // 入住
    //           orderConfig.rosterTable,
    //           // 记录
    //           orderConfig.record,
    //         ],
    //         success: ({ setHidden }) => {
    //           api.order.haveRoom
    //             .call(this, {
    //               url: '/room/order/refund',
    //               params: {
    //                 orderId: records.id,
    //               },
    //             })
    //             .then((res) => {
    //               if (res.code == 200) {
    //                 if (res.data == '5') {
    //                   this.$message.error('退款失败')
    //                 } else {
    //                   setHidden()
    //                 }
    //               } else {
    //                 this.$message.error(res.msg)
    //               }
    //             })
    //         },
    //       })
    //     }
    //   }
    //   if (records.fromType == '14') {
    //     this.getPackageDetail(records.id).then((detail) => {
    //       detail.orderItemList = detail.comboDetails.map((e) => ({
    //         ...e,
    //         productName: e.hotelRoomName ? e.productName + '/' + e.hotelRoomName : e.businessName + '/' + e.productName,
    //         productPic: '  ',
    //         totalPrice: e.salePrice,
    //       }))
    //       onDetail(detail)
    //     })
    //   } else {
    //     this.getDetailData(records.id).then(onDetail)
    //   }
    // },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            downloadAction(`/api/room/order/exportWl${getParams(data)}`, '酒店民宿.xlsx')
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        rightButton={this.getRightButton()}
        filterRightButtonKey={['refresh', 'setting']}
        // onSelectRow={this.onRowSelect}
      />
    )
  },
}
</script>

<style lang="less">
.product-order-item {
  display: flex;
  align-items: center;
  //   &:not(:last-child) {
  //     margin-bottom: 30px;
  //   }
  img {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: cover;
  }
}
</style>
