import api from '@/command/api'
import orderConfig from '@/orderConfig'
import { getAction, getParams, downloadAction } from '@/command/netTool'


export const hotelMixin = {
    methods: {
        // 获取选中订单详情
        getDetailData(id) {
            return new Promise((resolve, reject) => {
                getAction(`/api/room/order/detail?id=${id}`)
                    .then((result) => {
                        resolve(result.data)
                    })
                    .catch((e) => reject && reject(e))
            })
        },
        // 获取套餐详情
        getPackageDetail(id) {
            return new Promise((resolve, reject) => {
                getAction(`/api/combo/order/detail?id=${id}`)
                    .then((result) => {
                        resolve(result.data)
                    })
                    .catch((e) => reject && reject(e))
            })
        },
        onRowSelectHotel(records, isDetail) {
            const onDetail = (detail) => {
                let showButton = false
                if (isDetail) {
                    showButton = false
                } else {
                    if (detail.fromType != '14') {
                        showButton = true
                    }
                }
                orderConfig.completed({
                    data: detail,
                    params: {
                        data: { ...detail, showButton: showButton },
                        type: '',
                    },
                    executeFun: [
                        // 表格数据
                        orderConfig.table,
                        // 可选择表格
                        orderConfig.tableTicketsSelect,
                        // 退款信息
                        orderConfig.retuenInfo,
                        // 入住信息
                        orderConfig.checkInfo,
                        // 入住
                        orderConfig.rosterTable,
                        // 记录
                        orderConfig.record,
                    ],
                    success: ({ setHidden }) => {
                        api.order.haveRoom
                            .call(this, {
                                url: '/room/order/refund',
                                params: {
                                    orderId: records.id,
                                },
                            })
                            .then((res) => {
                                if (res.code == 200) {
                                    if (res.data == '5') {
                                        this.$message.error('退款失败')
                                    } else {
                                        setHidden()
                                    }
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                    },
                })
            }
            if (records.fromType == '14') {
                this.getPackageDetail(records.id).then((detail) => {
                    detail.orderItemList = detail.comboDetails.map((e) => ({
                        ...e,
                        productName: e.hotelRoomName ? e.productName + '/' + e.hotelRoomName : e.businessName + '/' + e.productName,
                        productPic: '  ',
                        totalPrice: e.salePrice,
                    }))
                    onDetail(detail)
                })
            } else {
                this.getDetailData(records.id).then(onDetail)
            }
        },
    }
}
